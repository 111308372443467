<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="26%"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="140px"
    >
      <el-form-item label="管理员" prop="uid">
        <el-select
          style="width: 280px"
          v-model="userValue"
          filterable
          remote
          reserve-keyword
          @change="changeManager"
          :placeholder="!dataForm.id ? '请输入关键词搜索' : dataForm.nickName"
          :remote-method="remoteManager"
          :loading="loading"
        >
          <el-option
            v-for="item in userList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="代理商名" prop="name">
        <el-input
          v-model="dataForm.name"
          placeholder="代理商名"
          style="width: 280px"
        ></el-input>
      </el-form-item>
      <el-form-item label="上级代理商" prop="pid">
        <el-select
          style="width: 280px"
          v-model="companyValue"
          filterable
          remote
          reserve-keyword
          @change="changeCompany"
          :placeholder="!dataForm.id ? '请输入关键词搜索' : dataForm.parentName"
          :remote-method="remoteCompany"
          :loading="loading"
        >
          <el-option
            v-for="item in companyList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="代驾年检服务费" prop="fuwufei">
        <el-input
          v-model="dataForm.fuwufei"
          placeholder="代驾年检服务费"
          style="width: 280px"
        ></el-input>
      </el-form-item>
      <el-form-item label="区域" prop="placeholder">
        <el-cascader
          v-model="placeholder"
          :options="provinces"
          :props="{ checkStrictly: true }"
          clearable
          :placeholder="placeholder"
          @change="changeSelect"
          style="width: 280px"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="平台所得佣金比例" prop="proportion">
        <el-input
          v-model="dataForm.proportion"
          placeholder="如输入0.15就是得15%，最多两位小数"
          style="width: 280px"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  getUserList,
  getCompanyInfo,
  getCompanyList,
  saveCompany,
  updateCompany,
} from "../../api/api";
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: 0,
        uid: "",
        pid: 0,
        name: "",
        fuwufei: 0,
        province: "",
        city: "",
        county: "",
        nickName: "",
        parentName: "",
        proportion: "",
      },
      placeholder: "", // 省市县回显
      userList: [], // 用户列表
      provinces: [], // 省市县数据
      companyList: [], // 代理商列表
      userValue: "",
      companyValue: "",
      loading: false,
      dataRule: {
        uid: [
          {
            required: true,
            message: "管理员不能为空",
            trigger: "blur",
          },
        ],
        name: [
          { required: true, message: "代理商名不能为空", trigger: "blur" },
        ],
        proportion: [
          { required: true, message: "抽成比例不能为空", trigger: "blur" },
        ],
        province: [{ required: true, message: "省不能为空", trigger: "blur" }],
        city: [{ required: true, message: "市不能为空", trigger: "blur" }],
        county: [
          { required: true, message: "县（区）不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 搜索用户
    remoteManager(query) {
      if (query !== "") {
        this.loading = true;
        this.userList = [];
        setTimeout(() => {
          // 请求用户列表，封装数据
          let requestData = { keyword: query };
          getUserList(requestData).then((data) => {
            if (data && data.code === 0) {
              for (let item of data.data.list) {
                let userOps = {};
                userOps.value = item.id;
                userOps.label =
                  item.nickName +
                  "(" +
                  (item.realName ? item.realName : "未实名") +
                  ")";
                this.userList.push(userOps);
              }
            } else {
              this.userList = [];
            }
          });
        }, 200);
        this.loading = false;
      }
    },
    // 选择管理员的事件
    changeManager(value) {
      this.dataForm.uid = value;
    },
    // 搜索代理商
    remoteCompany(query) {
      if (query !== "") {
        this.loading = true;
        this.companyList = [];
        setTimeout(() => {
          // 请求用户列表，封装数据
          let requestData = { keyword: query };
          getCompanyList(requestData).then((data) => {
            if (data && data.code === 0) {
              for (let item of data.data.list) {
                let companyOps = {};
                companyOps.value = item.id;
                companyOps.label = item.name;
                this.companyList.push(companyOps);
              }
            } else {
              this.companyList = [];
            }
          });
        }, 200);
        this.loading = false;
      }
    },
    // 选择父级代理商的事件
    changeCompany(value) {
      this.dataForm.pid = value;
    },
    // 初始化数据回显
    init(id) {
      this.companyValue = "";
      this.userValue = "";
      this.placeholder = "";
      // 省市县json数据
      const regions = require("../../public/regions.json");
      for (let province of regions) {
        let p = {};
        p.value = province.name;
        p.label = province.name;
        p.children = [];

        for (let city of province.children) {
          let c = {};
          c.value = city.name;
          c.label = city.name;

          if (city.children) {
            c.children = [];
            for (let county of city.children) {
              let cou = {};
              cou.value = county.name;
              cou.label = county.name;
              c.children.push(cou);
            }
          }

          p.children.push(c);
        }
        this.provinces.push(p);
      }

      // 初始化回显数据
      this.dataForm.id = id || 0;
      this.visible = true;

      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (this.dataForm.id) {
          getCompanyInfo({ id: this.dataForm.id }).then((data) => {
            if (data && data.code === 0) {
              this.dataForm = data.data;
              this.dataForm.fuwufei = this.dataForm.fuwufei / 100;
              this.placeholder =
                data.data.province +
                "/" +
                data.data.city +
                "/" +
                data.data.county;
            }
          });
        }
      });
    },
    // 选择省市县
    changeSelect(item) {
      this.dataForm.province = "";
      this.dataForm.city = "";
      this.dataForm.county = "";
      this.placeholder = "";
      if (item[0]) {
        this.dataForm.province = item[0];
        this.placeholder = item[0];
      }
      if (item[1]) {
        this.dataForm.city = item[1];
        this.placeholder = this.placeholder + "/" + item[1];
      }
      if (item[2]) {
        this.dataForm.county = item[2];
        this.placeholder = this.placeholder + "/" + item[2];
      }
    },
    // 表单提交
    dataFormSubmit() {
      if (this.dataForm.proportion > 1 || this.dataForm.proportion < 0) {
        this.$message.error("请正确输入抽成比例");
        return;
      }
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let requestDate = this.dataForm;
          let region = this.placeholder.split("/");
          if (region[0]) {
            requestDate.province = region[0];
          }
          if (region[1]) {
            requestDate.city = region[1];
          }
          if (region[2]) {
            requestDate.county = region[2];
          }
          requestDate.fuwufei = requestDate.fuwufei * 100;
          if (this.dataForm.id) {
            // 修改
            updateCompany(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "修改操作成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          } else {
            // 新增
            requestDate.id = "";
            saveCompany(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          }
        }
      });
    },
  },
};
</script>
