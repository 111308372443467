<template>
  <div class="mod-config">
    <el-form
      :inline="true"
      :model="dataForm"
      @keyup.enter.native="getDataList()"
    >
      <el-form-item>
        <el-input
          v-model="dataForm.keyword"
          placeholder="按关键字搜索"
          clearable
        ></el-input>
      </el-form-item>
      <el-cascader
        :options="provinces"
        :props="{ checkStrictly: true }"
        clearable
        placeholder="按区域搜索"
        @change="changeSelect"
        style="margin-right: 10px"
      ></el-cascader>
      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <el-table-column
        prop="id"
        header-align="center"
        align="center"
        label="编号"
      >
      </el-table-column>
      <el-table-column
        prop="nickName"
        header-align="center"
        align="center"
        label="管理员"
      >
      </el-table-column>

      <el-table-column
        prop="name"
        header-align="center"
        align="center"
        label="代理商名"
      >
      </el-table-column>
      <el-table-column
        prop="parentName"
        header-align="center"
        align="center"
        label="所属上级代理商"
      >
      </el-table-column>
      <el-table-column
        prop="fuwufei"
        header-align="center"
        align="center"
        label="代驾年检服务费"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.fuwufei / 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="proportion"
        header-align="center"
        align="center"
        label="抽成比例"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.proportion * 100 }}%</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="province"
        header-align="center"
        align="center"
        label="省"
      >
      </el-table-column>
      <el-table-column
        prop="city"
        header-align="center"
        align="center"
        label="市"
      >
      </el-table-column>
      <el-table-column
        prop="county"
        header-align="center"
        align="center"
        label="县（区）"
      >
      </el-table-column>
      <el-table-column
        prop="createDate"
        header-align="center"
        align="center"
        label="创建时间"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id)"
            >修改</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: center; margin-top: 10px">
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from "./company-add-or-update";
import { getCompanyList, deleteCompanyById } from "../../api/api";
export default {
  data() {
    return {
      dataForm: {
        keyword: "",
        province: "",
        city: "",
        county: "",
      }, //查询参数
      provinces: [], // 省市县数据
      dataList: [], //列表数据
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
  },
  activated() {
    this.getDataList();
  },
  created() {
    // 省市县json数据
    const regions = require("../../public/regions.json");
    for (let province of regions) {
      let p = {};
      p.value = province.name;
      p.label = province.name;
      p.children = [];

      for (let city of province.children) {
        let c = {};
        c.value = city.name;
        c.label = city.name;

        if (city.children) {
          c.children = [];
          for (let county of city.children) {
            let cou = {};
            cou.value = county.name;
            cou.label = county.name;
            c.children.push(cou);
          }
        }

        p.children.push(c);
      }
      this.provinces.push(p);
    }
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      this.dataForm.page = this.pageIndex;
      this.dataForm.limit = this.pageSize;
      getCompanyList(this.dataForm).then((data) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list;
          this.totalPage = data.data.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 选择省市县
    changeSelect(item) {
      if (item.length == 1) {
        this.dataForm.province = item[0];
        this.dataForm.city = "";
        this.dataForm.county = "";
      } else if (item.length == 2) {
        this.dataForm.city = item[1];
        this.dataForm.province = "";
        this.dataForm.county = "";
      } else if (item.length == 3) {
        this.dataForm.county = item[2];
        this.dataForm.province = "";
        this.dataForm.city = "";
      } else if (!item[0]) {
        this.dataForm.province = "";
        this.dataForm.city = "";
        this.dataForm.county = "";
      }
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(`确定要进行删除吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteCompanyById({
          id,
        }).then((data) => {
          if (data && data.code === 0) {
            this.$message({
              message: data.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList();
              },
            });
          } else {
            this.$message.error(data.msg);
          }
        });
      });
    },
  },
};
</script>
